* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.violet-gradient {
  background: #804dee;
  background: linear-gradient(-90deg, #804dee 0%, rgba(60, 51, 80, 0) 100%);
  background: -webkit-linear-gradient(
    -90deg,
    #804dee 0%,
    rgba(60, 51, 80, 0) 100%
  );
}
  
img {
  width: 100%;
  object-fit: cover;
  height: auto;
}

.button-85 {
  padding: 0.4em 1.5em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background-color: transparent;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  transition: 1s;
  border: 2px solid transparent;
}

.button-85:hover {
  border: 2px solid white;
  transition: 1s;
}

.backToTop {
  position: fixed;
  bottom: -100px;
  right: 70px;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: blue;
  box-shadow: 2px 2px 20px 3px blue;
  z-index: 1;
  transition: .5s linear;
}

.backToTopTwo {
  position: fixed;
  bottom: 70px;
  right: 70px;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: blue;
  box-shadow: 2px 2px 20px 3px blue;
  z-index: 1;
  transition: .5s;
}

.backToTop:hover,
.backToTopTwo:hover {
  cursor: pointer;
}

i.fa-angles-up {
  font-size: 25px;
  color: white;
}

@media(max-width: 768px) {
  .backToTop {
      width: 55px;
      height: 55px;
      bottom: -100px;
      right: 30px;
  }

  .backToTopTwo {
      width: 55px;
      height: 55px;
      bottom: 70px;
      right: 30px;
  }

  i.fa-angles-up {
      font-size: 18px;
  }
}

@media (max-width: 400px) {
  .backToTop {
    width: 50px;
    height: 50px;
    bottom: -100px;
    right: 30px;
  }

.backToTopTwo {
    width: 50px;
    height: 50px;
    bottom: 50px;
    right: 30px;
}

  .button-85 {
    padding: 0.3em 1em;
  }
}

@media (max-width: 350px) {
  .button-85 {
    padding: 0.3em 0.7em;
  }
}

::-webkit-scrollbar {
  width: 11px;
}

::-webkit-scrollbar-track {
  background-color: rgb(11, 11, 65);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(blue, crimson);
}

@supports (scrollbar-color: linear-gradient(blue, crimson) rgb(11, 11, 65)) {
  * {
    scrollbar-color: linear-gradient(blue, crimson) rgb(11, 11, 65);
    scrollbar-width: 11px;
    border-radius: 10px;
  }
}

html, body {
  background-color: #0a101e;
}